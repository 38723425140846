import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Currency } from '@sales-libs/settings/data-access';
import { SlSettingsCurrencyDependendPageComponent } from '@sales-libs/settings/feature';
import { SharedContractActions } from '@sales-libs/shared/feature';
import { UNITS } from '@sales-libs/shared/util';
import { Utilization } from '@sales-libs/upselling/data-access';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs';
import { SlUpsellingSelectors } from '../store';
import { SlUpsellingActions } from '../store/upselling.actions';

@Component({
  selector: 'sl-upselling-best-invest-input-data',
  templateUrl: './best-invest-input-data.component.html',
  styleUrls: ['./best-invest-input-data.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatRadioModule,
    MatIconModule,
    SlSettingsCurrencyDependendPageComponent,
  ],
})
export class SlUpsellingBestInvestInputDataComponent
  implements OnInit, OnChanges
{
  @Input() cartId: number;
  @Input() isReadOnly: boolean;
  @Input() resetExternalWorkingHourCosts: number;
  @Input() cancelSave: boolean;
  bestInvestForm: FormGroup;
  Units = UNITS;
  @Output() formStatusChange = new EventEmitter<{
    isValid: boolean;
    formData: Utilization;
    formUpdated: boolean;
  }>();
  currency: Currency;

  utilizationParameters: Utilization;
  updatedUtilizationParameters: Utilization;
  isFormUpdated = false;
  private initialExternalWorkingHourCostsValue: number | null = null;

  // eslint-disable-next-line no-useless-escape
  decimalPlacesRegex = /^\d*\.?\,?\d*$/;
  maximumHoursPerYear = 8784;
  maximumHoursPerDay = 24;
  maximumDaysPerYear = 366;

  hoursPerDayControlName = 'working_hours_per_day';
  hoursPerYearControlName = 'working_hours_per_year';
  daysPerYearControlName = 'working_days_per_year';
  shareLowActivityControlName = 'share_low_activity';
  settingUpControlName = 'share_setting_up';
  shareHighActivityControlName = 'share_high_activity';
  controlControlName = 'control';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    public actions: Actions,
  ) {
    this.bestInvestForm = this.formBuilder.group({
      utilization_km_per_year: ['', [Validators.required]],
      working_hours_per_year: [
        '',
        [Validators.required, Validators.max(this.maximumHoursPerYear)],
      ],
      working_hours_per_day: [
        '',
        [Validators.required, Validators.max(this.maximumHoursPerDay)],
      ],
      working_days_per_year: [
        '',
        [Validators.required, Validators.max(this.maximumDaysPerYear)],
      ],
      share_low_activity: ['', Validators.required],
      share_setting_up: ['', Validators.required],
      share_high_activity: ['', Validators.required],
      control: [
        '',
        [Validators.required, Validators.min(100), Validators.max(100)],
      ],
      costs_internal_working_hour: ['', Validators.required],
      costs_external_working_hour: ['', Validators.required],
    });

    this.bestInvestForm.statusChanges.subscribe(() => {
      this.isFormUpdated = this._hasFormChanged();

      const formValues = this.bestInvestForm.getRawValue();
      this.formStatusChange.emit({
        isValid: this.bestInvestForm.valid,
        formData: formValues,
        formUpdated: this.isFormUpdated,
      });
    });
  }

  ngOnInit(): void {
    this.getParamsFromSharedStore();

    if (this.isReadOnly) {
      this.bestInvestForm.disable();
    }
    this.fetchUpdatedParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['resetExternalWorkingHourCosts']) {
      this._resetToDealerDefaultValue();
    }
    if (changes['cancelSave'] && this.cancelSave) {
      this.getUtilizationForCart();
    }
  }

  loadCurrency(currency: Currency): void {
    this.currency = currency;
  }

  updateControlParameter() {
    const share_low_activity =
      this.bestInvestForm.get(this.shareLowActivityControlName)?.value || 0;
    const share_setting_up =
      this.bestInvestForm.get(this.settingUpControlName)?.value || 0;
    const share_high_activity =
      this.bestInvestForm.get(this.shareHighActivityControlName)?.value || 0;
    const sum = share_low_activity + share_setting_up + share_high_activity;

    this.bestInvestForm.get(this.controlControlName)?.setValue(sum);
  }

  updateUtilizationParameters(updateHoursPerYearInput?: boolean) {
    const hoursPerDay =
      this.bestInvestForm.get(this.hoursPerDayControlName)?.value || 0;
    const daysPerYear =
      this.bestInvestForm.get(this.daysPerYearControlName)?.value || 0;
    const hoursPerYear =
      this.bestInvestForm.get(this.hoursPerYearControlName)?.value || 0;

    if (
      hoursPerDay > 0 &&
      daysPerYear > 0 &&
      (updateHoursPerYearInput || hoursPerYear === 0)
    ) {
      // Calculate working hours per year
      this.bestInvestForm
        .get(this.hoursPerYearControlName)
        ?.setValue(parseFloat((hoursPerDay * daysPerYear).toFixed(2)));
    } else if (daysPerYear > 0 && hoursPerYear > 0) {
      // Calculate working hours per day
      this.bestInvestForm
        .get(this.hoursPerDayControlName)
        ?.setValue(parseFloat((hoursPerYear / daysPerYear).toFixed(2)));
      this.bestInvestForm.get(this.hoursPerDayControlName)?.markAsTouched();
    } else if (hoursPerDay > 0 && hoursPerYear > 0) {
      // Calculate working days per year
      this.bestInvestForm
        .get(this.daysPerYearControlName)
        ?.setValue(parseFloat((hoursPerYear / hoursPerDay).toFixed(0)));
    }
  }

  getParamsFromSharedStore(): void {
    this.store
      .pipe(
        select(SlUpsellingSelectors.utilization),
        filterTruthy(),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((state) => {
        this.utilizationParameters = state;
      });

    if (
      !this.utilizationParameters ||
      this.utilizationParameters?.cart_id !== this.cartId
    ) {
      this.getUtilizationForCart();
    } else {
      this._setBestInvestValues(this.utilizationParameters);
    }
  }

  getUtilizationForCart() {
    this.store.dispatch(
      SlUpsellingActions.getUtilizationForCart({
        cartId: this.cartId,
      }),
    );

    this.actions
      .pipe(ofType(SlUpsellingActions.getUtilizationForCartSuccess), take(1))
      .subscribe((event) => {
        this.utilizationParameters = event.utilization;
        this._setBestInvestValues(this.utilizationParameters);
      });

    this.actions
      .pipe(ofType(SlUpsellingActions.getUtilizationForCartError), take(1))
      .subscribe(() => {
        this.store.dispatch(
          SharedContractActions.createDefaultUtilizationForCart({
            cartId: this.cartId,
          }),
        );
      });

    this.actions
      .pipe(
        ofType(SharedContractActions.createDefaultUtilizationForCartSuccess),
        take(1),
      )
      .subscribe((event) => {
        this.utilizationParameters = event.utilization;
        this._setBestInvestValues(this.utilizationParameters);
      });
  }

  fetchUpdatedParameters(): void {
    this.actions
      .pipe(
        ofType(SlUpsellingActions.updateUtilizationSettingsSuccess),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((state) => {
        this._setBestInvestValues(state.utilizationSettings);
      });
  }

  private _hasFormChanged(): boolean {
    if (this.initialExternalWorkingHourCostsValue === null) {
      this.initialExternalWorkingHourCostsValue =
        this.utilizationParameters?.customer_parameters
          ?.costs_external_working_hour ?? null;
    }

    const initialParams = {
      ...(this.utilizationParameters?.customer_parameters ?? {}),
      costs_external_working_hour: this.initialExternalWorkingHourCostsValue,
    };

    return Object.keys(this.bestInvestForm.controls)
      .filter((key) => key !== 'control')
      .some(
        (key) => this.bestInvestForm.get(key)?.value !== initialParams[key],
      );
  }

  private _setBestInvestValues(utilization) {
    const customerParameters = utilization.customer_parameters;
    this.bestInvestForm.patchValue({
      utilization_km_per_year: customerParameters?.utilization_km_per_year,
      working_hours_per_year: customerParameters?.working_hours_per_year,
      working_hours_per_day: customerParameters?.working_hours_per_day,
      working_days_per_year: customerParameters?.working_days_per_year,
      share_low_activity: customerParameters?.share_low_activity,
      share_setting_up: customerParameters?.share_setting_up,
      share_high_activity: customerParameters?.share_high_activity,
      costs_internal_working_hour:
        customerParameters?.costs_internal_working_hour,
      costs_external_working_hour:
        customerParameters?.costs_external_working_hour,
    });
    this.updateControlParameter();
  }

  private _resetToDealerDefaultValue() {
    this.bestInvestForm.patchValue({
      costs_external_working_hour: this.resetExternalWorkingHourCosts,
    });
  }
}
